import React, {useState, useEffect} from "react";
import axios from "axios";
import SpotifyRankings from "./SpotifyRankings";
import defaultAlbumImage from "../images/cassette.jpg";
import "./SpotifyPlayer.css";

const SpotifyPlayer = ({accessToken, onSongChange}) => {
	const [userData, setUserData] = useState(null);
	const [lastPlayed, setLastPlayed] = useState(null);
	const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
	const [currentArtist, setCurrentArtist] = useState("");
	const [audioFeatures, setAudioFeatures] = useState("");

	const fetchUserData = async () => {
		try {
			const response = await axios.get("https://api.spotify.com/v1/me", {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			setUserData(response.data);
		} catch (error) {
			console.error("Error fetching user data:", error.response.data);
		}
	};

	const fetchCurrentlyPlaying = async () => {
		try {
			const response = await axios.get(
				"https://api.spotify.com/v1/me/player/currently-playing",
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);
			const currentTrack = response.data.item;
			if (currentTrack && currentTrack.album.id !== null) {
				onSongChange(currentTrack);
				setCurrentlyPlaying(currentTrack);
				const artistDetails = await getArtistDetails(
					currentTrack.artists[0].id,
				);
				setCurrentArtist(artistDetails);
				const features = await getAudioFeatures(currentTrack.id);
				setAudioFeatures(features);
			} else {
				fetchLastPlayed();
			}
		} catch (error) {
			console.error(
				"Error fetching currently playing track:",
				error.response.data,
			);
		}
	};

	const fetchLastPlayed = async () => {
		try {
			const response = await axios.get(
				"https://api.spotify.com/v1/me/player/recently-played?limit=1",
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);
			const lastPlayedTrack = response.data.items[0].track;
			setLastPlayed(lastPlayedTrack);
		} catch (error) {
			console.error("Error fetching last played track:", error.response.data);
		}
	};

	const getArtistDetails = async (artistId) => {
		try {
			const response = await axios.get(
				`https://api.spotify.com/v1/artists/${artistId}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching artist details:", error.response.data);
		}
	};

	const handleNextSong = async () => {
		try {
			await axios.post(
				"https://api.spotify.com/v1/me/player/next",
				{},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);
			fetchCurrentlyPlaying();
		} catch (error) {
			console.error("Error playing next song:", error.response.data);
		}
	};

	const handlePreviousSong = async () => {
		try {
			await axios.post(
				"https://api.spotify.com/v1/me/player/previous",
				{},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);
			fetchCurrentlyPlaying();
		} catch (error) {
			console.error("Error playing previous song:", error.response.data);
		}
	};

	const getAudioFeatures = async (trackId) => {
		try {
			const response = await axios.get(
				`https://api.spotify.com/v1/audio-features/${trackId}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching audio features:", error.response.data);
		}
	};

	useEffect(() => {
		fetchUserData();
		fetchCurrentlyPlaying();
		const intervalId = setInterval(() => {
			fetchCurrentlyPlaying();
		}, 5000);

		return () => clearInterval(intervalId);
	}, [accessToken]);

	return (
		<div className="spotify-player-container">
			{userData && (
				<div className="user-info">
					<h2>Welcome, {userData.display_name}</h2>
				</div>
			)}
			<div className="player-card">
				{currentlyPlaying ? (
					<div className="card-inner">
						<div className="card-header">
							<h4>Currently Playing</h4>
						</div>
						<div className="card-body">
							<h5>Song: {currentlyPlaying.name}</h5>
							<p>Album: {currentlyPlaying.album.name}</p>
							<p>Artist: {currentArtist?.name}</p>
							<img
								className="album-cover"
								src={currentlyPlaying.album.images[0]?.url || defaultAlbumImage}
								alt="Album Cover"
							/>
						</div>
						<div className="card-footer">
							<button onClick={handlePreviousSong}>Prev</button>
							<button onClick={handleNextSong}>Next</button>
						</div>
					</div>
				) : (
					<div className="no-music-message">
						<p>Nothing Playing</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default SpotifyPlayer;
